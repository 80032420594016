
import { Options, Vue } from 'vue-class-component'

@Options({components: {}})
export default class LolLeaderboards extends Vue {

    leaderboardPlatforms: Array<Record<string, string>> = []
    loading = true
    error = false
    errorDetail = ""
    maxIndex = 100
    players: Array<any> = []
    platform = ""
    sideAdMarginTop = 20

    mounted (): void {
        window.addEventListener('scroll', this.$generic.throttle(this.scrollHandler, 200))
    }

    beforeDestroy (): void {
        window.removeEventListener('scroll', this.$generic.throttle(this.scrollHandler, 200))
    }

    async created(): Promise<void> {
        this.platform = this.$route.params.platform.toString()
        if (this.$route.query.rendertron)
            this.maxIndex = 200
        this.$stge.loc['lol:leaderboardPlatform'] = this.platform
        this.leaderboardPlatforms = this.liftSelected(this.$asset.lol.fullPlatforms, this.platform, (item: any) => item.value)
        try {
            this.players = await this.$cdn.get('lol', ['leaderboards', this.platform], "json")
        } catch (e: any) {
            this.error = true
            this.errorDetail = e.response.data.detail
        }
        this.loading = false
        this.$meta.use({
            title: `Challenger Leaderboard - ${this.$css.capFirst(this.platform)} | StatHub.gg LoL`,
            og: {
                image: '/img/icons/android-chrome-maskable-192x192.png',
                description: (() => {
                    let str = "Top 10 Masters: "
                    for (const [rank, player] of this.$itertool.enumerate(this.players.slice(0, 10))) {
                        str += `${rank + 1}. ${player.summonerName} (${player.leaguePoints}LP) | `
                    }
                    return str
                })()
            }
        })
    }

    liftSelected <T>(arr: Array<T>, val: string, key: CallableFunction): Array<T> {
        let o: Array<T> = []
        for (const item of arr) {
            if (key(item) == val)
                o.unshift(item)
            else
                o.push(item)
        }
        return o
    }

    scrollHandler(): void {
        let sideColumnHeight = (this.$refs.sideColumn as HTMLElement)?.offsetHeight
        this.sideAdMarginTop = Math.max(window.pageYOffset - sideColumnHeight, 20)
    }

}
