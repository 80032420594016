<template>
    <Loading v-if="loading">LOADING</Loading>
    <Error v-else-if="error">{{errorDetail}}</Error>
    <div class="py-3" v-else>
        <div class="row mx-1 mx-lg-4 mx-xl-5">
            <div class="col-12 col-md-4 pe-md-4 text-left">
                <div ref="sideColumn">
                    <h3 class="text-truncate text-left mx-1">Challenger Leaderboard</h3>
                    <h6 class="mt-3 mx-1">Platform: <span class="capfirst">{{platform}}</span></h6>
                    <hr>
                    <div class="mt-3 board-nav-container">
                        <router-link
                            v-for="item in leaderboardPlatforms"
                            :key="'lol_leaderboard_' + item.value"
                            :class="[item.value == platform ? 'platform-btn-active': 'platform-btn', 'w-100 d-block px-3 py-2 d-flex border-bottom']"
                            :to="item.value"
                        >
                            <div>
                                {{item.title}}
                            </div>
                            <div class="ms-auto">
                                <Icon name="chevron-right" />
                            </div>
                        </router-link>
                    </div>
                </div>
                <VenatusAd class="mb-2 side-ad-scroll-transition" :style="{marginTop: sideAdMarginTop + 'px'}" v-if="$store.state.winWidth > 768" :height="1000" />
                <VenatusAd class="mt-2" v-else :height="100" />
            </div>
            <div class="col-12 col-md-8 mt-1 mt-md-0 ps-md-4 text-left">
                <div class="pt-2 px-1 d-flex text-muted justify-content-between small">
                    <div>Rank #</div>
                    <div>Summoner Name</div>
                    <div v-if="$store.state.winWidth >= 576">Win Rate / League Points</div>
                    <div v-else>Wr / LP</div>
                </div>
                <hr>
                <router-link
                    v-for="(player, rank) in players.slice(0, maxIndex)"
                    :key="'leaderboard_player_' + platform + rank"
                    :class="[rank < 10 ? 'top-bg': 'win-bg', 'mt-2 pt-2 pb-1 py-md-3 px-2 px-lg-3 d-flex justify-content-between align-items-center']"
                    :to="{name: 'LolProfile', params: {platform, name: player.summonerName}}"
                >
                    <div>
                        <h5 class="my-auto" v-if="$store.state.winWidth >= 576">{{rank + 1}}</h5>
                        <h6 class="my-auto" v-else>{{rank + 1}}</h6>
                    </div>
                    <div class="ps-3 ps-lg-5 text-truncate" style="width: 70%">
                        <Image
                            :src="`https://raw.communitydragon.org/latest/plugins/rcp-be-lol-game-data/global/default/v1/profile-icons/${player.profileIconId}.jpg`"
                            :size="$store.state.winWidth < 992 ? 36: 50"
                            class="me-3 rounded-circle"
                        />
                        <span v-if="$store.state.winWidth >= 576">{{player.summonerName}}</span>
                        <small v-else>{{player.summonerName}}</small>
                    </div>
                    <div :class="['text-warning text-nowrap text-end', $store.state.winWidth < 576 ? 'extra-small': '']">
                        <span class="text-info">{{$math.round((player.wins/(player.wins+player.losses)) * 100, 100)}}% ({{player.wins+player.losses}} games)</span>
                        <br>
                        <span class="capfirst">{{player.tier.toLowerCase()}}</span> {{player.leaguePoints}} LP
                    </div>
                </router-link>
                <div v-if="players.length < 50" class="null-bg mt-2 py-3 text-center">
                    <Error :inline="true">Ranks were recently reset, may need a few days to fill back up</Error>
                </div>
                <div v-if="maxIndex < players.length" class="null-bg mt-2 py-3 cursor-pointer text-center" @click="maxIndex = maxIndex + 100">
                    Show More
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>

@import "@/scss/variables.scss";
@import "@/scss/mixins.scss";
@import "@/scss/transitions.scss";

.board-nav-container {
    overflow-y: scroll;
}

@include media-breakpoints-down($md) {
    .board-nav-container {
        height: 130px;
        overflow-y: scroll;
    }
}
@include variable-transition("side-ad-scroll-transition", 'margin-top', 0.2s);

.platform-btn {
    background: linear-gradient(to right, rgba(31, 188, 255, 0.326) 0%, rgba(31, 188, 255, 0.19) 50%, rgba(31, 188, 255, 0.05) 100%);
}

.platform-btn-active {
    background: linear-gradient(to right, rgba(31, 255, 95, 0.326) 0%, rgba(31, 255, 95, 0.19) 50%, rgba(31, 255, 95, 0.05) 100%);
}

</style>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({components: {}})
export default class LolLeaderboards extends Vue {

    leaderboardPlatforms: Array<Record<string, string>> = []
    loading = true
    error = false
    errorDetail = ""
    maxIndex = 100
    players: Array<any> = []
    platform = ""
    sideAdMarginTop = 20

    mounted (): void {
        window.addEventListener('scroll', this.$generic.throttle(this.scrollHandler, 200))
    }

    beforeDestroy (): void {
        window.removeEventListener('scroll', this.$generic.throttle(this.scrollHandler, 200))
    }

    async created(): Promise<void> {
        this.platform = this.$route.params.platform.toString()
        if (this.$route.query.rendertron)
            this.maxIndex = 200
        this.$stge.loc['lol:leaderboardPlatform'] = this.platform
        this.leaderboardPlatforms = this.liftSelected(this.$asset.lol.fullPlatforms, this.platform, (item: any) => item.value)
        try {
            this.players = await this.$cdn.get('lol', ['leaderboards', this.platform], "json")
        } catch (e: any) {
            this.error = true
            this.errorDetail = e.response.data.detail
        }
        this.loading = false
        this.$meta.use({
            title: `Challenger Leaderboard - ${this.$css.capFirst(this.platform)} | StatHub.gg LoL`,
            og: {
                image: '/img/icons/android-chrome-maskable-192x192.png',
                description: (() => {
                    let str = "Top 10 Masters: "
                    for (const [rank, player] of this.$itertool.enumerate(this.players.slice(0, 10))) {
                        str += `${rank + 1}. ${player.summonerName} (${player.leaguePoints}LP) | `
                    }
                    return str
                })()
            }
        })
    }

    liftSelected <T>(arr: Array<T>, val: string, key: CallableFunction): Array<T> {
        let o: Array<T> = []
        for (const item of arr) {
            if (key(item) == val)
                o.unshift(item)
            else
                o.push(item)
        }
        return o
    }

    scrollHandler(): void {
        let sideColumnHeight = (this.$refs.sideColumn as HTMLElement)?.offsetHeight
        this.sideAdMarginTop = Math.max(window.pageYOffset - sideColumnHeight, 20)
    }

}
</script>